import ListAltTwoToneIcon from '@mui/icons-material/ListAltTwoTone';
import "./left-sidebar.scss";
// import LocalOfferTwoToneIcon from '@mui/icons-material/LocalOfferTwoTone';
import { Logout } from "@mui/icons-material";
import ChatTwoToneIcon from '@mui/icons-material/ChatTwoTone';
import Inventory2TwoToneIcon from '@mui/icons-material/Inventory2TwoTone';
// import MenuIcon from '@mui/icons-material/Menu';
// import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSidebar } from '../context/sidebarContext.jsx';
import logoLight from "../images/logo-light.svg";

export default function LeftSidebar({ pos }) {

  const positon = pos
  let navigate = useNavigate();
  const { myValue, setMyValue } = useSidebar();


  // useEffect(() => {
  //   setPosition(pos)
  //   // console.log("token",window.sessionStorage.getItem("token"))
  // }, []);
  // // const iconClicked = () =>{

  // //   console.log("clicked")

  // //   setOpenMenu(!openMenu)

  // // }

  useEffect(() => {

    console.log("my value", myValue);

  }, [myValue])

  // const logout = () => {

  //   window.sessionStorage.setItem("token",null);
  //   // navigate('/')
  // }
  const leftSidebarClick = () => {
    setMyValue(!myValue);
  }

  return <div className={myValue ? "left-sidebar left-sidebar-open" : "left-sidebar d-md-block d-none"} onClick={leftSidebarClick}>
    <div className="left-sidebar-inner px-3 pb-4 h-100">
      <div className="my-3 logo-block">
        {/* <a href="/home">
          
        </a> */}
        <h2>TOPSAI</h2>
      </div>
      <ul className="sidebar-ul px-0 h-100 d-flex flex-column pt-2" >
        <li className={positon === "1" ? "my-3 active" : "my-3"}><a href="# " className="stretched-link " onClick={() => { navigate("/csv"); }}><ListAltTwoToneIcon className="me-2" />Upload CSV</a></li>
        <li className={positon === "2" ? "my-3 active" : "my-3"}><a href="# " className="stretched-link " onClick={() => { navigate("/pdf"); }}><ChatTwoToneIcon className="me-2" />Chat With PDF</a></li>
        {/*<li className={positon == "2" ? "my-3 active" : "my-3"}><a href="" className="stretched-link" onClick={() => {navigate("/scrapping"); }}><LocalOfferTwoToneIcon className="me-2" />Using ASIN</a></li> */}
        <li className={positon === "3" ? "my-3 active" : "my-3"}><a href="# " className="stretched-link" onClick={() => { navigate("/reviews_input"); }}><ChatTwoToneIcon className="me-2" /> Paste Review</a></li>
        <li className={positon === "4" ? "my-3 active" : "my-3"}><a href="# " className="stretched-link" onClick={() => { navigate("/product_description"); }}><Inventory2TwoToneIcon className="me-2" />Product Description</a></li>
        <li className="mt-auto">
          <div className="logout">
            <p className="mb-0 p-1"><a href="/" className="text-white d-flex"><Logout className="icon text-red me-2" /> <span className="" >Logout</span></a></p>
            {/* <p>Settings</p> */}
          </div>
        </li>
      </ul>
    </div>
  </div>;
}
